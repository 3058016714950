  <template>
    <div class="view-form">
      <div class="logo-overlay">
      </div>
      <div class="loading" v-show="!isLoaded">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
      <div
        v-show="isLoaded"
        class="container main-container main-container--login main-container--login_gray"
      >
        <div class="row">
          <div class="col">
            <div
              v-if="confirmMessage !== null"
              class="alert alert-primary"
              role="alert"
            >
              {{ confirmMessage }}
            </div>
            <div v-if="success" class="alert alert-primary" role="alert">
              <span v-if="successCode === 'auth/reset-password'">{{
                'Your password has been reset' | translate
              }}</span>
            </div>
            <div v-if="error && errorMessage != 'You are already logged in on another device. Please logout from the other device before attempting to log in again.'" class="alert alert-danger" role="alert">
              <span v-if="errorCode === 'auth/cannot-verify'">{{
                'Sorry! We cannot verify your account at this time.' | translate
              }}</span>
              <span v-else-if="errorCode === 'auth/wrong-password'">{{
                'Incorrect username or password. Try again, select Forgot Password or contact your Zinpro Representative.'
                  | translate
              }}</span>
              <span v-else-if="errorCode === 'auth/already-verified'">{{
                'Your account has already been verified.' | translate
              }}</span>
              <span v-else-if="errorCode === 'auth/user-not-found'">{{
                'Incorrect username or password. Try again, select Forgot Password or contact your Zinpro Representative.'
                  | translate
              }}</span>
              <span v-else-if="errorCode === 'auth/something-wrong'">{{
                'Sorry! Something went wrong. Please try to login again.'
                  | translate
              }}</span>
              <span v-else>{{ errorMessage | translate }}</span>
            </div>
            <div class="card main-card">
              <div class="card-body p-0 p-md-3">
                <form
                  autocomplete="off"
                  @submit.prevent="signIn"
                  method="post"
                  novalidate
                >
                  <div class="form-group">
                    <label for="email" class="login__label">{{
                      'Email' | translate
                    }}</label>
                    <!-- routing needs to be changed -->
                    <input
                      ref="emailField"
                      type="email"
                      class="form-control mb-0"
                      id="email"
                      aria-describedby="email"
                      v-model="email"
                      tabindex="1"
                      :placeholder="$t('Email')"
                    />
                  </div>
                  <div class="form-group visible-eye-icon mb-0">
                    <label for="password" class="login__label">{{
                      'Password' | translate
                    }}</label>
                    <div class="input-group">
                      <input
                        class="form-control mb-0"
                        id="password"
                        v-model="pass"
                        :type="showPassword ? 'text' : 'password'"
                        tabindex="2"
                        :placeholder="$t('Password')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary"
                          type="button"
                          @click="togglePasswordVisibility"
                        >
                          <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-24">
                      <router-link
                        class="pull-right focus reset-password-link"
                        to="/password-reset"
                        tabindex="3"
                        >{{ 'Forgot Password?' | translate }}</router-link
                      >
                      </div>
                  </div>
                  <div class="row mb-2">
                    <div
                      class="col-md-24 d-flex align-items-center justify-content-end"
                    >
                      <button
                        type="submit"
                        tabindex="4"
                        class="btn btn-secondary btn-full--md"
                      >
                        {{ 'Login' | translate }}
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-md-24 d-flex align-items-center justify-content-center"
                    >
                    <a
                        class="text-center"
                        href="https://www.zinpro.com/contact-sales/?_expert_type=representative"
                        target="_blank"
                        >{{
                          'Don’t have an account? Contact a Zinpro Representative now!'
                            | translate
                        }}
                      </a>
                    </div>
                  </div>
                </form>
              </div>
              <!-- end card-body -->
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <modal-login
        v-if="isOpen"
        @close="isOpen = false"
        @onClick="modalSignIn"
        :headline="'Your account is currently active on another device or browser session' | translate"
        :description="
          `Would you like to log out of the other sessions? Click “Yes” to log out of all the other devices or “No” to logoff this device.`
            | translate
        "
      ></modal-login>
    </div>

  </template>

  <script>
  import { mapActions, mapState } from 'vuex';
  import AppHeader from '../components/AppHeader';
  import AppFooter from '../components/AppFooter';
  import ModalLogin from "@/components/misc/modal-login.vue";
  import {
    GET_SIGN_IN_USER,
    GET_EVALUATIONS,
    GET_USERS,
    GET_CLIENTS,
    GET_SALES_REPS,
    GET_FETCH_BASE64,
    GET_EVALUATION_SHARE_WITH_ME,
    GET_EVALUATION_SHARE_BY_ME
  } from '../store/types';
  import { cloneDeep, get } from 'lodash';
  import fetchAllData from '../mixins/fetchAllData';
  export default {
    components: {
      AppHeader,
      AppFooter,
      ModalLogin
    },
    mixins:[fetchAllData],
    data() {
      return {
        email: '',
        pass: '',
        success: false,
        successCode: null,
        confirmMessage: null,
        error: false,
        errorCode: null,
        errorMessage: null,
        isLoaded: true,
        isOpen: false,
        logoutSessionReceived: false,
        showPassword: false,
      };
    },
    created() {
      // if (this.user) {
      //   this.$router.push('/');
      // }
      if (this.$route.query.success_code) {
        this.success = true;
        this.successCode = this.$route.query.success_code;
      }
      if (this.$route.query.error_code) {
        this.error = true;
        this.errorCode = this.$route.query.error_code;
      }
    },

    computed: {
      ...mapState({
        user: state => state.users.user,
      }),
    },
    methods: {
      ...mapActions('users', [GET_SIGN_IN_USER, GET_USERS, GET_SALES_REPS]),
      // ...mapActions('shareWithMe', [GET_EVALUATION_SHARE_WITH_ME]),
      // ...mapActions('shareByMe', [GET_EVALUATION_SHARE_BY_ME]),
      ...mapActions('medias', [GET_FETCH_BASE64]),

      
      modalSignIn() {
        let resetLogin = true
        this.isOpen = false
        this.signIn(resetLogin);
      },
      generateErrorMsg({ errors, message = '' }) {
        return errors ? Object.values(errors).join(', ') : message;
      },





      async signIn(resetLogin) {
        this.isLoaded = false;
        if(resetLogin == true) {
          resetLogin = true
    } else {
      resetLogin = false
    }
        // this.$store.state.user = undefined;
        // Firebase Authentication from already created users
        this[GET_SIGN_IN_USER]({ email: this.email, password: this.pass, resetLogin })
          .then(async user => {
            this.confirmMessage = this.$t('Logged In Successfully');
            if (!this.user.isVerified) {
              this.$router.push('/verify-account');
            }
            this.$router.push('/');

            // this function populate the current logged user medias including profieImg, assessor medias
            this.populateLoggedInUserMedias();
    
            // funciton which is invoking on the then, its fetching all applications users, client, and assessor images
            Promise.all([
              this[GET_EVALUATIONS](),
              this[GET_USERS](),
              this[GET_SALES_REPS](),
              this[GET_CLIENTS](),
              this[GET_EVALUATION_SHARE_WITH_ME](),
              this[GET_EVALUATION_SHARE_BY_ME](),
            ]).then(this.fetchImgsForClientEvaluationsAndClient);
          })
          .catch(error => {
            this.error = true;
            this.errorCode = error.code;
            if(error.message == 'You are already logged in on another device. Please logout from the other device before attempting to log in again.') {
              this.isOpen = true
            }
            console.log(error);
            if (this.errorCode === 11) {
              location =
                window.location.href + '?error_code=auth/something-wrong';
              return;
            }
            
            this.errorMessage = this.generateErrorMsg(error);
            this.isLoaded = true;
          });
      },
      togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
      },
    },
  };
  </script>

  <style scoped>
  .input-group-append {
    cursor: pointer;
  }
  .visible-eye-icon .input-group-append .btn {
      min-width: unset;
  }
  .visible-eye-icon .input-group-append {
      height: 40px;
      top: 1px;
      position: relative;
      width: 51px;
  }
  .visible-eye-icon .input-group {
      max-width: 383px;
  }


  @media screen and (max-width: 767px) {
    .visible-eye-icon .input-group {
        max-width: 419px;
    }
  }
  @media screen and (max-width: 600px) {
    .visible-eye-icon .input-group {
      max-width: 100%;
  }
  .visible-eye-icon .input-group-append {
      top: 0px;
      right: -1px;
      height: 41px;

      position: absolute;
    }
  }
  </style>
