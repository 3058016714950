import { URLS } from '../../constants';
import axios from '../../../Axios';

import {
  GET_ADD_EMPTY_ARRAY_EVALUATION_INTO_USER,
  SET_ADD_EMPTY_ARRAY_EVALUATION_INTO_USER,
  GET_UPDATE_EVALUATION,
  SET_UPDATE_EVALUATION,
  GET_DELETE_EVALUATION,
  SET_DELETE_EVALUATION,
  GET_EVALUATION,
  SET_EVALUATION,
  GET_EVALUATION_BY_ID,
  SET_EVALUATION_BY_ID,
  GET_ADD_EVALUATION_IN_DB,
  SET_ADD_EVALUATION_IN_DB,
  SET_CLIENT_IN_EVALUATION,
  GET_EVALUATIONS,
  SET_EVALUATIONS,
  GET_EVALUATION_DELETE,
  SET_EVALUATION_DELETE,
  GET_EVALUATIONS_BY_ID_GETTER,
  SET_EVALUATION_GROUP,
  SET_EVALUATION_GROUP_DELETE,
  SET_UPDATE_ASSESSOR_IN_EVALUATION,
  SET_DELETE_ASSESSOR_IN_EVALUATION,
  GET_CUSTOMER_EVALUATIONS,
  SET_CUSTOMER_EVALUATIONS,
  GET_EVALUATION_BY_ID_GETTER,
  GET_EVALUATION_BY_USER_ID_GETTER,
  GET_USER_EVALUATIONS,
  GET_USER_EVALUATIONS_GETTER,
  SET_USER_EVALUATIONS,
  GET_EVALUATION_SHARE_WITH_ME,
  SET_EVALUATION_SHARE_WITH_ME,
  GET_EVALUATION_SHARE_BY_ME,
  SET_EVALUATION_SHARE_BY_ME,
  SET_SHARE_EVALUATION,
  SHARE_EVALUATION,
  SET_UNSHARE_EVALUATION_BY_ME,
  UNSHARE_EVALUATION,
  SET_EVALUATION_SHARE_BY_ME_ONE,
} from '../types';
import groups from './groups';

const state = {
  evaluations: [{}],
  evaluation: {},
  customerEvaluations: [],
  shareWithMe: [],
  shareByMe: [],
};

//In Component: this.$store.getters.functionName;
const getters = {
  getEvaluationId: state => {
    return state.evaluation.id;
  },
  [GET_EVALUATIONS_BY_ID_GETTER]: STATE => EVALUATION_ID => {
    return (STATE.evaluations = STATE.evaluations.filter(
      ({ _id }) => _id !== EVALUATION_ID
    ));
  },
  [GET_EVALUATION_BY_ID_GETTER]: STATE => EVALUATION_ID => {
    let evaluation = (STATE.evaluation = STATE.evaluations.find(
      ({ _id }) => _id === EVALUATION_ID
    ));
    if (!evaluation)
      evaluation = STATE.evaluation = STATE.customerEvaluations.find(
        ({ _id }) => _id === EVALUATION_ID
      );

    return evaluation;
  },
  [GET_EVALUATION_BY_USER_ID_GETTER]: STATE => USER_ID => {
    return STATE.evaluations.filter(({ userId }) => userId === USER_ID);
  },
  [GET_USER_EVALUATIONS_GETTER]: (STATE, GETTERS, ROOT_STATE) => USER_ID => {
    console.log({ ROOT_STATE });
    return STATE.evaluations.filter(({ userId }) => userId === USER_ID);
  },
};

const mutations = {
  setEvaluation: (STATE, payload) => {
    return (STATE.evaluation.id = payload.value);
  },

  [SET_SHARE_EVALUATION](STATE, payload) {
    STATE.shareByMe.push(payload);
  },

  [SET_EVALUATION_SHARE_BY_ME](STATE, evaluationByMe) {
    STATE.shareByMe = evaluationByMe;
  },

  [SET_EVALUATION_SHARE_BY_ME_ONE](STATE, evaluationByMe) {
    STATE.shareByMe.push(evaluationByMe);
  },

  [SET_EVALUATION_SHARE_WITH_ME](STATE, evaluationWithMe) {
    STATE.shareWithMe = evaluationWithMe;
  },

  [SET_UNSHARE_EVALUATION_BY_ME](STATE, payload) {
    STATE.shareByMe = STATE.shareByMe.filter(evaluation => 
    evaluation._id === payload.evaluation && evaluation.sharedWith._id !== payload.sharedWith._id);
  },

  [SET_ADD_EMPTY_ARRAY_EVALUATION_INTO_USER](STATE) {
    STATE.user.evaluations = [];
  },

  [SET_CUSTOMER_EVALUATIONS](STATE, customerEvaluations) {
    STATE.customerEvaluations = customerEvaluations;
  },

[SET_ADD_EVALUATION_IN_DB](STATE, evaluation) {
    // if evaluation not exist add it, otherwise replace it.
    const alreadyExistEvaluationIndex = STATE.evaluations.findIndex(
      ({ _id }) => _id === evaluation._id
    );

    // replace it
    if (alreadyExistEvaluationIndex > -1) {
      STATE.evaluations[alreadyExistEvaluationIndex] = evaluation;
    } else {
      // add it
      const existInCustomerEvaluation =
        STATE.customerEvaluations.findIndex(
          ({ _id }) => _id === evaluation._id
        );

      if (existInCustomerEvaluation > -1) {
        STATE.customerEvaluations[existInCustomerEvaluation] =
          evaluation;
      } else {
        STATE.evaluations.unshift(evaluation);
      }
    }
  },
  [SET_EVALUATIONS](STATE, evaluations) {
    STATE.evaluations = evaluations;
  },
  [SET_EVALUATION_BY_ID](STATE, evaluation) {
    STATE.evaluation = evaluation;
  },
  [SET_UPDATE_EVALUATION](STATE, evaluation) {
    STATE.evaluations = STATE.evaluations.map(evalu =>
      evalu._id === evaluation._id ? evaluation : evalu
    );

    if (STATE.evaluation._id === evaluation._id) STATE.evaluation = evaluation;
  },
  [SET_EVALUATION_DELETE](STATE, EVALUATION_ID) {
    STATE.evaluations = STATE.evaluations.filter(
      ({ _id }) => _id !== EVALUATION_ID
    );

    console.log(STATE.evaluations);
  },
  [SET_CLIENT_IN_EVALUATION](STATE, client) {
    STATE.evaluations = STATE.evaluations.map(evaluation =>
      evaluation.clientId === client.id
        ? { ...evaluation, clientData: client }
        : evaluation
    );
  },

  [SET_EVALUATION_GROUP](STATE, GROUP) {
    STATE.evaluations = STATE.evaluations.map(evaluation =>
      evaluation._id === GROUP.evaluationId
        ? setGroupInEvaluation(STATE.evaluation, GROUP)
        : evaluation
    );

    STATE.customerEvaluations = STATE.customerEvaluations.map((evaluation) =>
      evaluation._id === GROUP.evaluationId
        ? setGroupInEvaluation(STATE.evaluation, GROUP)
        : evaluation
    );
    
    if (STATE.evaluation._id === GROUP._id) {
     setGroupInEvaluation(STATE.evaluation ,GROUP);
    }
  },

  [SET_EVALUATION_GROUP_DELETE](STATE, { evaluationId, groupId }) {
    let deleteGroupAssessorFromOrdserArr;

    STATE.evaluations = STATE.evaluations.map(evaluation => {
      if (evaluation._id === evaluationId) {
        evaluation.groups = Object.fromEntries(
          Object.entries(evaluation.groups).filter(
            ([group_id, group_obj]) => {

              // if(group_id === groupId)
              // // deleteGroupAssessorFromOrdserArr = Object.keys(group_obj.assessors);
             
              return group_id !== groupId
            }
          )
        );

        return evaluation;
      }
      return evaluation;
    });

    
    delete STATE.evaluation.groups[groupId];

    const assessorInOtherGroup = Object.values(STATE.evaluation.groups).map(group => Object.keys(group.assessors)).flat()
    let newOrder= []
    STATE.evaluation.order.forEach(assessor => {
      if(assessorInOtherGroup.includes(assessor))
      newOrder.push(assessor)
    }) 

    STATE.evaluation.order = newOrder

  },

  [SET_UPDATE_ASSESSOR_IN_EVALUATION](
    STATE,
    { evaluationId, groupId, assessor }
  ) {
    STATE.evaluations = STATE.evaluations.map(ev => {
      if (ev._id === evaluationId)
        ev.groups[groupId].assessors[assessor.id] = assessor;
      return ev;
    });

    if (STATE.evaluation._id === evaluationId)
      STATE.evaluation.groups[groupId].assessors[assessor.id] = assessor;
  },
  [SET_DELETE_ASSESSOR_IN_EVALUATION](
    STATE,
    { evaluationId, groupId, assessorId }
  ) {
    STATE.evaluations = STATE.evaluations.map(ev => {
      if (ev._id === evaluationId) {
        // delete from order array
        ev.order.splice(ev.order.indexOf(assessorId), 1);

        // delete from assessorList
        ev.groups[groupId].assessor_list.splice(
          ev.groups[groupId].assessor_list.indexOf(assessorId),
          1
        );

        // delete from assessor object
        ev.groups[groupId].assessors = Object.fromEntries(
          Object.entries(ev.groups[groupId].assessors).filter(
            ([id, obj]) => id !== assessorId
          )
        );
        return ev;
      }
      return ev;
    });

    if (STATE.evaluation._id === evaluationId)
      STATE.evaluation.groups[groupId].assessors[assessor.id] = assessor;
  },
};
const actions = {
  setEvaluation: ({ commit }, payload) => {
    console.log(payload.id);
    commit('setEvaluation', payload);
  },

  [GET_ADD_EMPTY_ARRAY_EVALUATION_INTO_USER]({ commit: COMMIT }) {
    COMMIT(SET_ADD_EMPTY_ARRAY_EVALUATION_INTO_USER, { root: true });
  },

  [GET_EVALUATION_DELETE]({ commit: COMMIT }, EVALUATION_ID) {
    COMMIT(SET_EVALUATION_DELETE, EVALUATION_ID);
    return new Promise(function(resolve, reject) {
      axios
        .delete(`${URLS.EVALUATION}/${EVALUATION_ID}`)
        .then(function(res) {})
        .catch(function(err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },
  [GET_USER_EVALUATIONS]({ commit: COMMIT }, USER_ID) {
    return new Promise(function(resolve, reject) {
      axios
        .get(URLS.EVALUATION, { params: { userId: USER_ID } })
        .then(function(res) {
          // COMMIT(SET_USER_EVALUATIONS rest.data.data)
          resolve(res.data.data);
        })
        .catch(function(err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },

  [GET_ADD_EVALUATION_IN_DB]({ commit: COMMIT }, EVALUATION) {
    COMMIT(`evaluations/${SET_ADD_EVALUATION_IN_DB}`, EVALUATION, {
      root: true,
    });
    // return new Promise(function(resolve, reject) {
    axios
      .post(URLS.EVALUATION, EVALUATION)
      .then(function(res) {
        return res.data.data;
      })
      .catch(function(err) {
        return err.response ? err.response.data : err;
      });
    // });
  },

  [GET_UPDATE_EVALUATION]({ commit: COMMIT }, EVALUATION) {
    COMMIT(SET_UPDATE_EVALUATION, EVALUATION);
    return new Promise(function(resolve, reject) {
      axios
        .post(`${URLS.EVALUATION}/${EVALUATION._id}`, EVALUATION)
        .then(function(res) {
          // COMMIT(SET_UPDATE_EVALUATION, res.data.data);
          return resolve(res.data.data);
        })
        .catch(function(err) {
          // return reject(err.response ? err.response.data : err);
          return;
        });
    });
  },

  [GET_DELETE_EVALUATION]({ commit: COMMIT }, evaluationId) {
    return new Promise(function(resolve, reject) {
      axios
        .delete(`${URLS.EVALUATION}/${evaluationId}`)
        .then(function(res) {
          COMMIT(SET_DELETE_EVALUATION, res.data.data);
          return resolve(res.data.data);
        })
        .catch(function(err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },

  [GET_EVALUATION]({ commit: COMMIT }, evaluationId) {
    return new Promise(function(resolve, reject) {
      axios
        .get(`${URLS.EVALUATION}`)
        .then(function(res) {
          COMMIT(SET_EVALUATION, res.data.data);
          return resolve(res.data.data);
        })
        .catch(function(err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },

  [GET_EVALUATION_BY_ID]({ commit: COMMIT }, evaluationId) {
    return new Promise(function(resolve, reject) {
      axios
        .get(`${URLS.EVALUATION}/${evaluationId}`)
        .then(function(res) {
          COMMIT(SET_EVALUATION_BY_ID, res.data.data);
          return resolve(res.data.data);
        })
        .catch(function(err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },
  [GET_CUSTOMER_EVALUATIONS]({ commit: COMMIT }, userId) {
    return new Promise(function(resolve, reject) {
      axios
        .get(URLS.EVALUATION, { params: { userId } })
        .then(function(res) {
          COMMIT(SET_CUSTOMER_EVALUATIONS, res.data.data);
          return resolve(res.data.data);
        })
        .catch(function(err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },
  [GET_EVALUATIONS]({ commit: COMMIT }, customerId) {
    return new Promise(function(resolve, reject) {
      axios
        .get(URLS.EVALUATION)
        .then(function(res) {
          COMMIT(SET_EVALUATIONS, res.data.data);
          return resolve(res.data.data);
        })
        .catch(function(err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },


  [GET_EVALUATION_SHARE_WITH_ME]({ commit: COMMIT }) {
    
    return new Promise(function(resolve, reject) {
      axios
        .get(URLS.EVALUATION_SHARE)
        .then(function(res) {
          COMMIT(SET_EVALUATION_SHARE_WITH_ME, res.data.data);
          return resolve(res.data.data);
        })
        .catch(function(err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },

  [GET_EVALUATION_SHARE_BY_ME]({ commit: COMMIT }) {
    
    return new Promise(function(resolve, reject) {
      axios
        .get(`${URLS.EVALUATION_SHARE}?type=sharedBy`)
        .then(function(res) {
          COMMIT(SET_EVALUATION_SHARE_BY_ME, res.data.data);
          return resolve(res.data.data);
        })
        .catch(function(err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },


  [SHARE_EVALUATION]({ commit }, data) {
    return new Promise((resolve, reject) => {
      
      axios
        .post(`${URLS.EVALUATION_SHARE}`, { evaluation: data.evaluation, sharedWith: data.sharedWith._id })
        .then(response => {
          commit(SET_EVALUATION_SHARE_BY_ME_ONE, data);
          resolve(response.data.data);
        })
        .catch(error => {
          reject(error.response ? error.response.data : error);
        });
    });
  },


  [UNSHARE_EVALUATION]({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URLS.EVALUATION_SHARE}/delete`, { evaluation: data.evaluation, sharedWith: data.sharedWith._id })
        .then(response => {
          commit(SET_UNSHARE_EVALUATION_BY_ME, data);
          resolve(response.data.data);
        })
        .catch(error => {
          reject(error.response ? error.response.data : error);
        });
    });
  },
};
function setGroupInEvaluation(EVALUATION, GROUP) {
  return {
    ...EVALUATION,
    groups: {
      ...EVALUATION.groups,
      [GROUP.id]: GROUP,
    },
  };
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
