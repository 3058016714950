export const URLS = {
  // auth
  SIGNIN: "/auth/signin",
  SIGN_OUT: "/auth/signout",
  SIGNUP: "/auth/signup",
  RESET_PASSWORD: "/auth/reset-password",
  CHANGE_PASSWORD: "/auth/set-password",
  VERIFY_PASSWORD: "/auth/verify-password",

  // user
  USER: "/user",
  ME: "/user/me",
  UPDATE_USER: "/user",
  RESEND_INVITE: "/user/resend-invite",

  UNVERIFIED_USER: "/user/unverify-user",
  RESEND_INVITE: "/user/resend-invite",

  // company
  COMPANY: "/company",

  // Evaluation
  EVALUATION: "/evaluation",
  GROUP: "/evaluation/group",
  ASSESSORS: "/evaluation/assessor/",

  // CLIENT
  CLIENT: "/client",

  // Emial-template
  EMAIL_TEMPLATE: "/emailTemplate",

  // MEDIAS
  MEDIAS: "/file",
  DOC: "file/document",

  // MEDIAS
  REQUEST_REPORT: "/evaluation/request-report",
  LIBRARY: "/library",
  EVALUATION_SHARE: "/shareEvaluation",
};

export const ROLES = {
  data_entry: "data_entry",
  external: "external",
  zinpro_representative: "zinpro_representative",
  admin: "admin",
};

/* I'm making the space available for row insert base on the language, 
because few require more text and long word like spanish, and few languages are require less page to write rows on title. */
export const getPageSize = (lang, overrideSize) => {
  const sizes = {
    "es":9.5,
    "ch":14,
    "fr":9.6,
    "en": 10.7,
    ...overrideSize
  }

  switch (lang) {
    case "es":
      return sizes.es;
      break;
    case "ch":
      return sizes.ch;
    case "fr":
      return sizes.fr;
      break;
    case "en":
      return sizes.en;
      break;
    default:
      return 10.7;
      break;
  }
};
