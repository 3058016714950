//@TODO These imports can be reduxed by creating an index.js file and immediately import each compoenet then exporting. The index.js can then be referenced contianing each component in an object, for example: import (a,b,c,d) from ./components
import Vue from 'vue';
import Router from 'vue-router';
import { store } from './store/store';
import Login from './views/Login.vue';
import Logout from './views/Logout.vue';
// import SignUp from './views/SignUp.vue';
import VerifyAccount from './views/VerifyAccount.vue';
import PasswordReset from './views/PasswordReset.vue';
import ChangePassword from './views/ChangePassword.vue';
import HerdCharacteristics from './pages/evaluations/HerdCharacteristics.vue';
import QuickStart from './pages/evaluations/QuickStart.vue';
import Evaluations from './pages/evaluations/Evaluations.vue';
import Dashboard from './pages/dashboard/Dashboard.vue';
import AssessorOrder from './pages/evaluations/AssessorOrder.vue';
import Groups from './pages/groups/Groups.vue';
import MyPhotos from './pages/my_photos/my_photos.vue';
import AssessorBase from './pages/assessors/assessor-base.vue';
import AssessorSummary from './pages/assessors/assessorSummary.vue';
import Home from './pages/Home.vue';
import MyProfile from './pages/MyProfile.vue';
import TermsOfUse from './pages/TermsOfUse.vue';
import Reports from './pages/reports/Reports.vue';
import Cookie from 'js-cookie';
import PageNotFound from './pages/NotFound.vue';

//Admin Imports
import AdminHome from './pages/admin/Home.vue';
import AdminInviteCustomer from './pages/admin/Customers/InviteCustomer.vue';
import ManageCustomers from './pages/admin/Customers/ManageCustomers.vue';
import UserInfo from './pages/admin/Customers/UserInfo.vue';
import UploadUserGuides from './pages/admin/Upload/UploadUserGuides';
import UploadDataCollections from './pages/admin/Upload/UploadDataCollections';
import UploadTranslations from './pages/admin/Translations/Translations';
import Settings from './pages/admin/Settings/Settings';
import EmailTemplate from './pages/admin/EmailTemplate/EmailTemplate.vue';
import EmailAddressChange from './pages/admin/EmailTemplate/emailForms/EmailAddressChange.vue';
import EmailAddressVerification from './pages/admin/EmailTemplate/emailForms/Verify.vue';
import EnrollmentNotification from './pages/admin/EmailTemplate/emailForms/EnrollmentNotification.vue';
import PasswordResetTab from './pages/admin/EmailTemplate/emailForms/PasswordResetTab.vue';
import SmtpSetting from './pages/admin/EmailTemplate/emailForms/SmtpSetting.vue';

import ManageCompanies from './pages/admin/Companies/ManageCompanies.vue';
import NewCompany from './pages/admin/Companies/NewCompany.vue';
import EditCompany from './pages/admin/Companies/EditCompany.vue';
import { ROLES, URLS  } from './constants';
import {GET_USER_BY_ID} from './store/types';

let router = new Router({
  hashbang: false,
  history: true,
  mode: "history", // Removes # from url
  routes: [
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        isPublic: true,
      },
    },
    //Admin Routes
    {
      path: "/admin/",
      name: "AdminHome",
      component: AdminHome,
      meta: {
        requiresAuth: true,
        adminOnly: true,
      },
    },
    {
      path: "/admin/customer-management/",
      name: "ManageCustomers",
      component: ManageCustomers,
      meta: {
        requiresAuth: true,
        adminOnly: true,
      },
    },
    {
      path: "/admin/customer-management/:representativeId",
      name: "ManageCustomers",
      component: ManageCustomers,
      meta: {
        requiresAuth: true,
        adminOnly: true,
      },
    },
    {
      path: "/admin/company-management/",
      name: "ManageCompanies",
      component: ManageCompanies,
      meta: {
        requiresAuth: true,
        adminOnly: true,
      },
    },
    {
      path: "/admin/new-company/",
      name: "NewCompany",
      component: NewCompany,
      meta: {
        requiresAuth: true,
        adminOnly: true,
      },
    },
    {
      path: "/admin/company/:id",
      name: "EditCompany",
      component: EditCompany,
      meta: {
        requiresAuth: true,
        adminOnly: true,
      },
    },
    {
      path: "/admin/invite-customer/",
      name: "InviteCustomer",
      component: AdminInviteCustomer,
      meta: {
        requiresAuth: true,
        adminOnly: true,
      },
    },
    {
      path: "/admin/user/:id",
      name: "UserInfo",
      component: UserInfo,
      meta: {
        requiresAuth: true,
        adminOnly: true,
      },
    },
    {
      path: "/admin/upload-forms/",
      name: "UploadForms",
      component: UploadDataCollections,
      meta: {
        requiresAuth: true,
        adminOnly: true,
      },
    },
    {
      path: "/admin/upload-translations/",
      name: "UploadTranslations",
      component: UploadTranslations,
      meta: {
        requiresAuth: true,
        adminOnly: true,
      },
    },
    {
      path: "/admin/settings/",
      component: Settings,
      name: "Settings",
      meta: {
        requiresAuth: true,
        adminOnly: true,
      },
    },
    {
      path: "/admin/email-template/",
      component: EmailTemplate,
      name: "EmailTemplate",
      meta: {
        requiresAuth: true,
        adminOnly: true,
      },
      children: [
        {
          path: "/admin/email-template/email-address-change",
          component: EmailAddressChange,
          name: "EmailAddressChange",
          meta: {
            requiresAuth: true,
            adminOnly: true,
          },
        },
        {
          path: "/admin/email-template/verification-email",
          component: EmailAddressVerification,
          name: "EmailAddressVerification",
          meta: {
            requiresAuth: true,
            adminOnly: true,
          },
        },
        {
          path: "/admin/email-template/enrollment-notification",
          component: EnrollmentNotification,
          name: "EnrollmentNotification",
          meta: {
            requiresAuth: true,
            adminOnly: true,
          },
        },
        {
          path: "/admin/email-template/request-report",
          component: EmailAddressVerification,
          name: "RequestReport",
          meta: {
            requiresAuth: true,
            adminOnly: true,
          },
        },
        {
          path: "/admin/email-template/Password-reset",
          component: EmailAddressVerification,
          name: "PasswordResetTab",
          meta: {
            requiresAuth: true,
            adminOnly: true,
          },
        },
        {
          path: "/admin/email-template/smtp-setting",
          component: SmtpSetting,
          name: "SmtpSetting",
          meta: {
            requiresAuth: true,
            adminOnly: true,
          },
        },
      ],
    },
    {
      path: "/admin/profile",
      name: "MyProfile",
      component: MyProfile,
      meta: {
        requiresAuth: true,
        adminOnly: true,
      },
    },
    //Regular Sales Person
    {
      path: "/password-reset",
      name: "PasswordReset",
      component: PasswordReset,
      meta: {
        isPublic: true,
      },
    },
    {
      path: "/reset-password",
      name: "ChangePassword",
      component: ChangePassword,
      meta: {
        isPublic: true,
      },
    },
    {
      path: "/verify-account",
      name: "VerifyAccount",
      component: VerifyAccount,
      meta: {
        isPublic: true,
      },
    },
    {
      path: "/logout",
      name: "logout",
      component: Logout,
      meta: {
        // isPublic: true,
      },
    },
    // {
    //   path: '/sign-up',
    //   component: SignUp,
    //   meta: {
    //     isPublic: true,
    //   },
    // },
    {
      path: "/",
      alias: ["/home", "/index.html"],
      name: "home",
      component: Home,
      meta: {
        // isPublic: true,
      },
    },
    {
      path: "/profile",
      name: "MyProfile",
      component: MyProfile,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/terms-of-use",
      name: "TermsOfUse",
      component: TermsOfUse,
      meta: {
        isPublic: true,
        isPrivate: true,
      },
    },
    {
      path: "/reports",
      name: "Reports",
      component: Reports,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/evaluations",
      name: "Evaluations",
      component: Evaluations,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/users/:userId/evaluations/:evaluationId/order/",
      name: "AssessorOrder",
      component: AssessorOrder,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/users/:userId/evaluations/:evaluationId/assessor-summary/",
      name: "AssessorSummary",
      component: AssessorSummary,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/users/:userId/evaluations/:evaluationId/groups/:groupId/assessors/:assessorType",
      name: "AssessorBase",
      component: AssessorBase,
      meta: {
        requiresAuth: true,
        subPage: null,
      },
    },
    {
      path: "/users/:userId/evaluations/:evaluationId/groups/:groupId/assessors/:assessorType/footbaths/:footbathType/:footbathId/mixes/",
      name: "HygieneFootbath",
      component: AssessorBase,
      meta: {
        requiresAuth: true,
        subPage: "mix",
      },
    },
    {
      path: "/users/:userId/evaluations/:evaluationId/groups/:groupId/assessors/:assessorType/footbaths/new/",
      name: "HygieneFootbath",
      component: AssessorBase,
      meta: {
        requiresAuth: true,
        subPage: "new-footbath",
      },
    },
    {
      path: "/users/:userId/evaluations/:evaluationId/groups/:groupId/assessors/:assessorType/footbaths/existing/",
      name: "HygieneFootbath",
      component: AssessorBase,
      meta: {
        requiresAuth: true,
        subPage: "existing-footbath",
      },
    },
    {
      path: "/users/:userId/evaluations/:evaluationId/groups/",
      name: "Groups",
      component: Groups,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/evaluations/new",
      name: "HerdCharacteristics",
      component: HerdCharacteristics,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/users/:userId/evaluations/:evaluationId",
      name: "HerdCharacteristics",
      component: HerdCharacteristics,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/quick-start/",
      name: "QuickStart",
      component: QuickStart,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/quick-start/:evaluationId",
      name: "QuickStart",
      component: QuickStart,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/my-photos/",
      name: "MyPhotos",
      component: MyPhotos,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/users/:userId/evaluations/:evaluationId/groups/:groupId/assessors/:assessorType/dashboard",
      name: "Dashboard",
      component: Dashboard,
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/users/:userId/evaluations/:evaluationId/groups/:groupId/assessors/:assessorType/main",
      name: "TransitionMain",
      component: AssessorBase,
      meta: {
        requiresAuth: true,
        subPage: "main",
      },
    },
    {
      path: "/users/:userId/evaluations/:evaluationId/groups/:groupId/assessors/:assessorType/summary",
      name: "TransitionSummary",
      component: AssessorBase,
      meta: {
        requiresAuth: true,
        subPage: "summary",
      },
    },
    {
      path: '/users/evaluations/:evaluationId/share/:evaluationName',
      name: 'ShareEvaluation',
      component: () => import('@/pages/evaluations/shareEvaluation.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: "*",
      component: PageNotFound,
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.matched[0].meta.subPage !== undefined) {
    store.state.subPage = to.matched[0].meta.subPage;
  }

  if (to.query && to.query.assessor_nav !== undefined) {
    Cookie.set('assessor_nav', to.query.assessor_nav, { expires: 365 });
  }

  const tokenNotExpired =
    new Date(localStorage.getItem('expireAt')) > new Date();

  // if (tokenNotExpired) {
  //   console.log(tokenNotExpired, store.state.users);
  //   store.state.users.user = {};
  //   return localStorage.clear();
  // }

  const isLoggedIn = localStorage.getItem('token') && tokenNotExpired;
  const role = localStorage.getItem('role');
  const adminRoles = store.state.adminRoles;

  const { isPublic, isPrivate } = to.meta || {};

  const loggedUserAccessPublicRoute = isLoggedIn && isPublic && !isPrivate;
  const notAuthorized = isLoggedIn && !adminRoles.includes(role);

  // prevent to access private rout while not logged in
  if (!isLoggedIn && to.path !== '/login' && !isPublic) {
    // await store.restored;
    store.state.users.user = {};
    return next({ path: '/login' });
  }

  // prevent to access public route while logged
  if (notAuthorized && to.path.includes('admin')) {
    await store.restored;
    return next({ path: '/' });
  }
  if (loggedUserAccessPublicRoute && to.path !== '/logout') {
    await store.restored;
    return next({ path: '/' });
  }

  if (isLoggedIn) {
    try {
      await store.dispatch('users/GET_USER_BY_ID');
    } catch (error) {
      console.error('Failed to perform action on route change:', error);
    }
  }
  if (!isLoggedIn && to.name !== 'login' && to.meta.requiresAuth) {
    next({ name: 'login' });
  } else if (isLoggedIn && to.name === 'login') {
    next({ path: '/' }); 
  } else {
    next();
  }

  await store.restored;
  next();
});

Vue.use(Router);

export default router;
