import {
  evaluationArrayToObj,
  removeAuthInLocalStorage,
  imgUrlToBase64,
} from '../../helperFunctions';
import _ from 'lodash';
import axios from '../../../Axios.js';
import { ROLES, URLS } from '../../constants.js';
import { setAuthInLocalStorage } from '../../helperFunctions.js';
import {
  GET_CHANGE_PASSWORD,
  GET_INVITE_CUSTOMER,
  GET_LOGOUT_USER,
  GET_REQUEST_EMAIL_FORGOT_PASSWORD,
  GET_RESEND_INVITE,
  GET_RESET_PASSWORD_EMAIL,
  GET_SIGN_IN_USER,
  GET_UPDATE_USER,
  GET_USERS,
  GET_USER_BY_ID,
  GET_USERS_BY_ID_GETTER,
  GET_USERS_BY_ROLE_GETTER,
  GET_USER_LOGGED_FROM_LOCAL_STORAGE,
  SET_INVITE_CUSTOMER,
  SET_LOGOUT_USER,
  SET_REQUEST_EMAIL_FORGOT_PASSWORD,
  SET_RESEND_INVITE,
  SET_SIGN_IN_USER,
  SET_UPDATE_USER,
  SET_USERS,
  SET_USER_BY_ID,
  SET_USER_LOGGED_FROM_LOCAL_STORAGE,
  GET_UNVERIFIED_USER,
  GET_UPDATE_UNVERIFIED_USER,
  GET_VERIFY_USER_PASSWORD,
  GET_DELETE_USER,
  SET_DELETE_USER,
  SET_CUSTOMER_BY_ID,
  GET_CUSTOMER_BY_ID,
  UPDATE_LOGIN_USER,
  GET_UPLOAD_PROFILE_IMAGE,
  SET_UPLOAD_PROFILE_IMAGE,
  SET_UPDATE_LOGIN_USER,
  GET_UPDATE_CUSTOMER,
  GET_UPDATE_ME,
  SET_UPDATE_ME,
  SET_UPDATE_UNVERIFIED_USER,
  GET_REPRESENTATIVE_USERS,
  SET_REPRESENTATIVE_USERS,
  GET_SALES_REPS,
  SET_SALES_REPS
} from "../types";
import router from '../../router';

const state = () => ({
  user: {},
  users: [],
  customer: null,
  salesReps : []
});

//Async
const actions = {
  [GET_SALES_REPS]({ commit }) {

    return new Promise((resolve, reject) => {
      axios
        .get(`${URLS.USER}/salesrep`)
        .then(response => {
          commit(SET_SALES_REPS, response.data.data);
          resolve(response.data.data);
        })
        .catch(error => {
          reject(error.response ? error.response.data : error);
        });
    });
  },
  [GET_USERS]({ commit: COMMIT }) {
    return new Promise(function (resolve, reject) {
      axios
        .get(URLS.USER)
        .then(function (res) {
          COMMIT(SET_USERS, res.data.data);
          return resolve(res.data.data);
        })
        .catch(function (err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },

  [GET_USER_BY_ID]({ commit: COMMIT }, USER_ID = "") {
    return new Promise(function (resolve, reject) {
      axios
        .get(URLS.ME, { params: { id: USER_ID } })
        .then(function (res) {
          COMMIT(SET_USER_BY_ID, res.data.data);
          return resolve(res.data.data);
        })
        .catch(function (err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },
  [GET_CUSTOMER_BY_ID]({ commit: COMMIT }, USER_ID = "") {
    return new Promise(function (resolve, reject) {
      axios
        .get(URLS.ME, { params: { id: USER_ID } })
        .then(function (res) {
          COMMIT(SET_CUSTOMER_BY_ID, res.data.data);
          resolve(res.data.data);
        })
        .catch(function (err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },
  [GET_UPDATE_ME]({ commit: COMMIT }, FIELDS_TO_UPDATE) {
    COMMIT(SET_UPDATE_ME, FIELDS_TO_UPDATE);
    const { medias, ...restData } = FIELDS_TO_UPDATE;
    return new Promise(function (resolve, reject) {
      axios
        .post(URLS.ME, {
          ...restData,
          ...(medias ? { medias: deleteOldImgsBas64(medias) } : {}),
        })
        .then(function (res) {
          resolve();
        })
        .catch(function (err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },
  [GET_REPRESENTATIVE_USERS]({ commit: COMMIT }, REPRESENTATIVE_ID) {
    return new Promise(function (resolve, reject) {
      axios
        .get(`${URLS.USER}/customers/${REPRESENTATIVE_ID}`)
        .then(function (res) {
          resolve(res.data.data);
        })
        .catch(function (err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },
  [GET_UPDATE_CUSTOMER]({ commit: COMMIT }, CUSTOMER = {}) {
    COMMIT(SET_CUSTOMER_BY_ID, CUSTOMER);
    return new Promise(function (resolve, reject) {
      axios
        .post(`${URLS.UPDATE_USER}/${CUSTOMER._id}`, CUSTOMER)
        .then(function (res) {
          return resolve(res.data.data);
        })
        .catch(function (err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },

  [GET_UNVERIFIED_USER]({ commit: COMMIT }, USER_ID = "") {
    return new Promise(function (resolve, reject) {
      axios
        .get(URLS.UNVERIFIED_USER, { params: { id: USER_ID } })
        .then(function (res) {
          return resolve(res.data.data);
        })
        .catch(function (err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },
  [GET_UPDATE_UNVERIFIED_USER]({ commit: COMMIT }, USER) {
    return new Promise(function (resolve, reject) {
      axios
        .put(`${URLS.UNVERIFIED_USER}/${USER._id}`, USER)
        .then(function (res) {
          const { user, ...tokenInfo } = res.data.data;
          COMMIT(SET_SIGN_IN_USER, { ...user, tokenInfo });
          return resolve(res.data.data);
        })
        .catch(function (err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },
  [GET_VERIFY_USER_PASSWORD]({ commit: COMMIT }, PASSWORD) {
    return new Promise(function (resolve, reject) {
      axios
        .post(URLS.VERIFY_PASSWORD, { password: PASSWORD })
        .then(function (res) {
          return resolve(res.data.data);
        })
        .catch(function (err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },

  // ___________________________________ AUTH ACTIONS ______________________________
  [GET_SIGN_IN_USER]({ commit: COMMIT }, PAYLOAD) {
    return new Promise((resolve, reject) => {
      axios
        .post(URLS.SIGNIN, PAYLOAD)
        .then((USER) => {
          const { user, ...tokenInfo } = USER.data.data;
          COMMIT(SET_SIGN_IN_USER, { ...user, tokenInfo });
          resolve();
        })
        .catch((err) => {
          return reject(err.response ? err.response.data : err);
        });
    });
  },

  [GET_LOGOUT_USER]({ commit: COMMIT }) {
    COMMIT(SET_LOGOUT_USER);
  },

  [GET_REQUEST_EMAIL_FORGOT_PASSWORD](
    { commit: COMMIT },
    { EMAIL, LANG }
  ) {
    return new Promise(function (resolve, reject) {
      axios
        .post(URLS.RESET_PASSWORD, { email: EMAIL, lang: LANG })
        .then(function (res) {
          return resolve(res);
        })
        .catch(function (err) {
          return reject(err.response.data);
        });
    });
  },

  [GET_USER_LOGGED_FROM_LOCAL_STORAGE]({ commit: COMMIT }, PAYLOAD) {
    COMMIT(SET_USER_LOGGED_FROM_LOCAL_STORAGE, PAYLOAD);
  },

  [GET_INVITE_CUSTOMER]({ commit: COMMIT }, CUSTOMER) {
    return new Promise(function (resolve, reject) {
      axios
        .post(URLS.SIGNUP, CUSTOMER)
        .then(function (res) {
          console.log(res.data.user);
          COMMIT(SET_INVITE_CUSTOMER, res.data.user);
          return resolve(CUSTOMER);
        })
        .catch(function (err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },

  [GET_RESET_PASSWORD_EMAIL]({ commit: COMMIT }, { EMAIL, LANG }) {
    return new Promise(function (resolve, reject) {
      axios
        .post(URLS.RESET_PASSWORD, { email: EMAIL, lang: LANG })
        .then(function (res) {
          // COMMIT(SET_USER_BY_ID, res.data.data);
          return resolve(res.data.message);
        })
        .catch(function (err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },
  [GET_CHANGE_PASSWORD]({ commit: COMMIT }, PASSWORDS) {
    return new Promise(function (resolve, reject) {
      axios
        .post(URLS.CHANGE_PASSWORD, PASSWORDS)
        .then(function (res) {
          return resolve(res.data.data);
        })
        .catch(function (err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },

  [GET_RESEND_INVITE]({ commit: COMMIT }, EMAIL_TO) {
    return new Promise(function (resolve, reject) {
      axios
        .post(URLS.RESEND_INVITE, EMAIL_TO)
        .then(function (res) {
          COMMIT(SET_RESEND_INVITE, res.data.data);
          return resolve(res.data.data);
        })
        .catch(function (err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },

  async [GET_UPDATE_USER](
    { commit: COMMIT },
    USER_FIELDS_NEED_TO_UPDATE
  ) {
    console.log({ USER_FIELDS_NEED_TO_UPDATE });
    COMMIT(SET_SIGN_IN_USER, USER_FIELDS_NEED_TO_UPDATE);
    const { medias, ...restData } = USER_FIELDS_NEED_TO_UPDATE;
    return new Promise(function (resolve, reject) {
      axios
        .post(
          `${URLS.UPDATE_USER}/${USER_FIELDS_NEED_TO_UPDATE._id}`,
          {
            ...restData,
            ...(medias ? { medias: deleteOldImgsBas64(medias) } : {}),
          }
        )
        .then(function ({ data }) {
          return resolve(data.user);
        })
        .catch(function (err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },

  [GET_DELETE_USER]({ commit: COMMIT }, USER_ID) {
    return new Promise(function (resolve, reject) {
      axios
        .delete(`${URLS.USER}/${USER_ID}`)
        .then(function (res) {
          COMMIT(SET_DELETE_USER, USER_ID);
          return resolve(res.data.data);
        })
        .catch(function (err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },

  [GET_UPLOAD_PROFILE_IMAGE]({ commit: COMMIT }, media) {
    return new Promise(function (resolve, reject) {
      axios
        .post(URLS.MEDIAS, media, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(function (res) {
          return resolve(res.data.data);
        })
        .catch(function (err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },
};

const mutations = {
  [SET_UPDATE_UNVERIFIED_USER]() {},
  [SET_UPDATE_LOGIN_USER](STATE, USER) {
    STATE.user = USER;
  },

  [SET_UPDATE_ME](STATE, USER) {
    STATE.user = {
      ...STATE.user,
      ...USER,
      medias: { ...(STATE.user.medias || {}), ...(USER.medias || {}) },
    };

    setAuthInLocalStorage({ lang: USER.lang });
  },

  [SET_SIGN_IN_USER](STATE, PAYLOAD) {
    const { tokenInfo = {}, ...resetUserData } = PAYLOAD;
    // protecting to not to set set user as null or undefined

    STATE.user = { ...(STATE.user || {}), ...resetUserData };
    console.log({ user: STATE.user });

    // STATE.user = {
    //   ...(STATE.user || {}), ...resetUserData,

    //   /* sometime we just received the the single image which is frontend sent
    //   to backend it so we merge the single image which is recently added with */

    //   medias: { ...(user.medias || {}), ...(resetUserData.medias || {}) }
    //   };

    // if user come with token its mean user is logging in, other wise we're using this to update the user as well
    if (tokenInfo.token)
      setAuthInLocalStorage({
        ...tokenInfo,
        role: resetUserData.role,
        lang: resetUserData.lang,
      });
  },
  async [SET_LOGOUT_USER](STATE, PAYLOAD) {
    STATE.user = {};
    STATE.users = [];

    await removeAuthInLocalStorage();
    router.push('/login');
  },
  
  [SET_USER_LOGGED_FROM_LOCAL_STORAGE](STATE, USER) {
    STATE.isLoggedIn = true;
    STATE.user = USER;
  },

  [SET_USERS](STATE, USERS) {
    STATE.users = USERS;
  },

  [SET_SALES_REPS](STATE, SALES_REPS) {
    STATE.salesReps = SALES_REPS;
  },
  [SET_CUSTOMER_BY_ID](STATE, CUSTOMER) {
    if (CUSTOMER === null) {
      console.log('doing null');
      STATE.customer = null;
    }

    STATE.users = STATE.users.map(user =>
      user._id === CUSTOMER._id ? CUSTOMER : user
    );

    if (STATE.customer && STATE.customer._id === CUSTOMER._id)
      STATE.customer = CUSTOMER;
  },

  [SET_INVITE_CUSTOMER](STATE, CUSTOMER) {
    STATE.users.unshift(CUSTOMER);
  },

  [SET_USER_BY_ID](STATE, USER) {
    const isLoggedInUser = USER._id === STATE.user._id;
    const alreadyExist = STATE.users.find(user => user._id === user._id);

    // only add the user in users list if user will not exist in the list.
    if (!alreadyExist && !isLoggedInUser) {
      STATE.users.unshift(USER);
    }
  },

  [SET_DELETE_USER](STATE, USERID) {
    STATE.users = STATE.users.filter(user => user._id !== USERID);
    deleteCustomerFromRepresentor(STATE.user, USERID);
  },

  [SET_RESEND_INVITE](STATE, USER) {},
};

export const getters = {
  [GET_USERS_BY_ROLE_GETTER]: STATE => ROLE => {
    return STATE.users.filter(user => user.role === ROLE);
  },

  [GET_USERS_BY_ID_GETTER]: STATE => ID => {
    let user = STATE.users.find(user => user._id === ID);
    if (!user) return STATE.user._id === ID ? STATE.user : null;
    return user;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

function converstAllImagesUrlsToBase64(user) {
  const lenghtOfMedias = user.medias && Object.keys(user.medias).length;

  if (!lenghtOfMedias) return {};

  return new Promise(async (resolve, reject) => {
    // convert all assessor images url to "base64Text" .
    let count = 0;
    for await (let img of Object.keys(user.medias)) {
      try {
        count++;
        user.medias[img].base64Text = await imgUrlToBase64(
          user.medias[img].base64
        );

        /* we make this if condition we just only want to return,
	        when we created all the url into base64, and then end return,
	       the medias object back to the parent function */
        if (lenghtOfMedias === count) resolve(user.medias);
      } catch (error) {
        continue;
      }
    }
  });
}

function deleteCustomerFromRepresentor(USER, USERID) {
  if (USER.role === ROLES.zinpro_representative) {
    const foundCustomerIndex = USER.customers.findIndex(c => c.id === USERID);
    if (foundCustomerIndex > -1) USER.customers.splice(foundCustomerIndex, 1);
  }
}

function deleteOldImgsBas64(hashImagesObj) {
  const imgsWhichNeedToAddInDB = {};

  Object.keys(hashImagesObj).forEach(imgId => {
    const { fileName, ...imgObj } = hashImagesObj[imgId];
    if (fileName) {
      imgsWhichNeedToAddInDB[imgId] = { fileName, ...imgObj, base64: '' };
    } else {
      imgsWhichNeedToAddInDB[imgId] = imgObj;
    }
  });
  return imgsWhichNeedToAddInDB;
}
